<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>
            {{ this.$t('general.programs') }}
          </li>
          <li>{{ this.$t('general.all_programs') }}</li>
        </ul>
      </nav>
    </div>
    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ this.$t('menu.company_dashboard.program') }} </span>
        </h2>
      </div>
      <div class="section-header-right">
        <router-link :to="{ name: 'company.program.add' }" class="btn btn-default">
          <i class="uil-plus"></i> {{ this.$t('general.add_program') }}
        </router-link>
      </div>
    </div>
    <template v-if="errorMessages">
      <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red">
          <i class="uil-exclamation-triangle"></i> {{ error }}
        </p>
      </div>
    </template>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <!-- <div>
              <a href="#" class="btn btn-icon btn-hover btn-circle" aria-expanded="false">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop" uk-drop="mode: hover; pos: right-center; offset: 0" style="left: 91px; top: 159.297px;">
                <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" :placeholder="$t('general.search')" autofocus="" v-model="filter.search">
                </form>
              </div>
            </div> -->
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" />
            </div>
          </div>
          <div class="d-flex">
            <!-- Shorting -->
            <a href="#" class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.sort_list')" title=""
              aria-expanded="false">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div id="sortDropDown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
              class="uk-dropdown">
              <ul class="uk-nav uk-dropdown-nav">
                <li @click="sortOrder('title', 'asc')">
                  <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                </li>
                <li @click="sortOrder('title', 'desc')">
                  <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                </li>
                <li @click="sortOrder('created_at', 'asc')">
                  <a href="javascript:void(0)">
                    {{ $t("sorting.created_at_asc") }}</a>
                </li>
                <li @click="sortOrder('created_at', 'desc')">
                  <a href="javascript:void(0)">
                    {{ $t("sorting.created_at_desc") }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Table -->
      <template>
        <div class="" v-if="programs_loading">
          <default-loading></default-loading>
        </div>
        <div v-else class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ this.$t('messages.title') }}</th>
                <th scope="col">{{ this.$t('general.description') }}</th>
                <th scope="col" class="text-center">Bölüm/İçerik</th>
                <th scope="col">{{ $t("general.added_at") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <!--user item-->
              <tr v-for="item in formattedItems">
                <th scope="row">
                  <router-link :to="{
                    name: 'company.programs.edit',
                    params: { id: item.id },
                  }" class="name h6 mb-0 text-sm">
                    {{ item.title }}
                  </router-link>
                </th>
                <td>{{ item.description }}</td>
                <td class="text-center">
                  {{ item.total_section }} / {{ item.total_content }}
                </td>
                <td>{{ item.created_at }}</td>
                <td class="text-right">
                  <div class="actions ml-3">
                    <router-link :to="{
                      name: 'company.programs.edit',
                      params: { id: item.id },
                    }" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('faq_categories.edit')">
                      <i class="uil-edit "></i>
                    </router-link>
                    <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Sil"
                      title="" @click="removeItem(item.id)" aria-expanded="false">
                      <i class="uil-minus-circle text-danger"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
          @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import moment from "moment";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_ITEMS,
  DELETE_ITEM,
  LOADING,
} from "@/core/services/store/program.module";

export default {
  name: "AnnouncementList",
  components: { Pagination, DefaultLoading },
  data() {
    return {
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        sort: "id",
        order: "desc",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    programs_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItems() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          title: currentItem.title,
          description: currentItem.description ? currentItem.description : "-",
          total_section: currentItem.sections_count,
          total_content: currentItem.contents_count,
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getResult() {
      let self = this;
      UIkit.dropdown("#sortDropDown").hide();
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: self.filter.search,
          page: self.page,
          perPage: self.per_page,
          sort: self.filter.sort,
          order: self.filter.order,
        },
      });
    },
    removeItem(id) {
      this.$store
        .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
          url: BASE_URL + "/" + id,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.getResult();
          } else this.errorMessages.push(this.error);
        });
    },
    sortOrder(sort, order, is_user = true) {
      let self = this;
      self.page = 1;
      self.filter.sort = sort;
      self.filter.order = order;

      this.getResult();
    },
    setPage(page, is_user = true) {
      let self = this;
      self.page = page;
      this.getResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getResult();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
